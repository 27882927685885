import { Layout } from '@/components/Layout/Layout';
import { ISearchOverlay } from 'components/HeaderNavigation/components/MobileNavigation';
import ClientProductsSubcategorySlugPage, {
  MinifiedCategory,
} from 'components/PageLevelComponents/ClientProductsSubcategorySlugPage';

import type { ISocialMediaComponent } from 'components/SocialMedia/types';
import { ANONYMOUS_TOKEN_NAME, LOCALE_DEFAULT } from 'config/config';
import { GetServerSideProps } from 'next';
import { ProductsSubcategoryPageController } from 'server/controller/page/products/subcategoryPageController';
import { ProductSubcategoryPage } from 'server/repository/hygraph/generated/graphqlTypes';
import { IProductsSubcategoryPageResource } from 'server/resource/page/productsSubcategoryPageController';

const RootPage = (props: IProductsSubcategoryPageResource) => {
  const {
    metaData,
    searchItemLabels,
    recipeCopy,
    breadcrumbsCMSData,
    footerNavData,
    footerImage,
    usercentricsIds,
    locale,
    searchOverlayData,
    countrySelectorData,
    newsletterData,
    contactUsUrl,
    difficultyCopy,
    getHeaderNav,
    getMobileNavData,
    popularSearchData,
    cardCarouselCMSData,
    recallData,
    subCategoryProducts,
    subcategory,
    followUsData,
    globalLabels,
    isSubSubCategory,
    category,
    productsCopy,
    pathTranslations,
    userToken,
  } = props;

  return (
    <Layout
      themeName={undefined}
      pathTranslations={pathTranslations}
      locale={locale}
      globalMetadata={metaData}
      getHeaderNav={getHeaderNav}
      getMobileNavData={getMobileNavData}
      popularSearchData={popularSearchData}
      footerNavData={footerNavData}
      footerImage={footerImage}
      usercentricsIds={usercentricsIds}
      searchOverlayData={searchOverlayData}
      countrySelectorData={countrySelectorData}
      newsletterData={newsletterData}
      contactUsUrl={contactUsUrl}
      difficultyCopy={difficultyCopy as Record<string, string>}
      cardCarouselCMSData={cardCarouselCMSData}
      recallData={recallData}
      userToken={userToken}
    >
      <ClientProductsSubcategorySlugPage
        subCategoryProducts={subCategoryProducts}
        subcategory={subcategory as ProductSubcategoryPage}
        followUsData={followUsData as unknown as ISocialMediaComponent}
        globalLabels={globalLabels}
        breadcrumbsCMSData={breadcrumbsCMSData}
        category={category as MinifiedCategory}
        isSubSubCategory={isSubSubCategory}
        productsCopy={
          productsCopy as {
            value: string;
          }
        }
        difficultyCopy={difficultyCopy as Record<string, string>}
        searchItemLabels={searchItemLabels as Record<string, string>}
        searchOverlayData={searchOverlayData as ISearchOverlay}
        recipeCopy={recipeCopy}
      />
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  params,
  defaultLocale,
  resolvedUrl,
  req,
}) => {
  const anonymousToken = req.cookies[ANONYMOUS_TOKEN_NAME];
  return ProductsSubcategoryPageController.getCachedSubcategoryPageData({
    resolvedUrl,
    locale: locale || defaultLocale || LOCALE_DEFAULT,
    slug: Array.isArray(params?.slug) ? params?.slug : [],
    userToken: anonymousToken ?? '',
  });
};

export default RootPage;
