'use client';

import { SortItemType, useSort } from 'hooks/useSort';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import TemplateMapper from 'templates/TemplateMapper';

import type { IBreadcrumbTitles } from 'components/Breadcrumbs';
import type { ISearchOverlay } from 'components/HeaderNavigation/components/MobileNavigation';
import type { ISocialMediaComponent } from 'components/SocialMedia/types';
import type { ICardItem } from 'components/Subcategory';
import type { ProductSubcategoryPage } from 'server/repository/hygraph/generated/graphqlTypes';
import type { AvailableTemplates } from 'templates/TemplateMapper/types';
import type { DictionaryItem } from 'service/dictionary/dictionaryService';
import type { ProductGridProduct } from 'types/Product';

import { FilterTopic } from '../FilterMenu/types';
import {
  FilterItemType,
  filterItems,
  getFiltersList,
} from 'server/repository/algolia/filter/Filters';
import { TrackingService } from 'service/tracking/trackingService';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { ProductsService } from 'service/products/productsService';

const IntroText = dynamic(() => import('components/IntroText').then((m) => m.IntroText), {
  ssr: true,
});

const Breadcrumbs = dynamic(
  () => import('components/Breadcrumbs').then((m: any) => m.Breadcrumbs),
  { ssr: true },
) as any;

const FilterMenu = dynamic(() => import('components/FilterMenu').then((m: any) => m.FilterMenu), {
  ssr: true,
}) as any;

const ProductGrid = dynamic(
  () => import('components/ProductGrid').then((m: any) => m.ProductGrid),
  { ssr: true },
) as any;

const SliderCarousel = dynamic(
  () => import('components/SliderCarousel').then((m) => m.SliderCarousel),
  { ssr: true },
);

const SocialMedia = dynamic(() => import('components/SocialMedia').then((m) => m.SocialMedia), {
  ssr: true,
});

const Subcategory = dynamic(
  () => import('components/Subcategory').then((m: any) => m.Subcategory),
  { ssr: true },
) as any;

const Spacer = dynamic(() => import('components/Spacer').then((m) => m.Spacer), {
  ssr: true,
});

// TODO: move this else where
export type MinifiedCategory = {
  slug: string;
  name: string;
};

function ClientProductsSubcategorySlugPage({
  subcategory,
  productsCopy,
  followUsData,
  subCategoryProducts,
  searchItemLabels,
  globalLabels,
  breadcrumbsCMSData,
  searchOverlayData,
  category,
  isSubSubCategory,
  difficultyCopy,
  recipeCopy,
}: {
  subcategory: ProductSubcategoryPage;
  productsCopy: {
    value: string;
  };
  followUsData: ISocialMediaComponent;
  subCategoryProducts: ProductGridProduct[];
  searchItemLabels: Record<string, string>;
  globalLabels: Record<string, string>;
  breadcrumbsCMSData: IBreadcrumbTitles;
  searchOverlayData: ISearchOverlay;
  difficultyCopy: Record<string, string>;
  recipeCopy: DictionaryItem[];
  category: MinifiedCategory;
  isSubSubCategory: boolean;
}) {
  const { pathTranslations } = useGlobalContext();
  const { getAvailableSortMethods, sortItems } = useSort(SortItemType.PRODUCT);
  const [currentSortMethod, setCurrentSortMethod] = useState<string>(
    () => getAvailableSortMethods()[0],
  );

  const heroCarouselData = subcategory?.heroCarouselComponent;
  const introData = subcategory?.introText;
  const breadcrumbsCategories = [
    {
      slug: pathTranslations.products,
      displayName: breadcrumbsCMSData?.productsText?.value || 'products',
    },
  ];
  if (!isSubSubCategory) {
    breadcrumbsCategories.push({
      slug: `${pathTranslations.productsCategory}${category.slug}`,
      displayName: category.name || '',
    });
  } else {
    breadcrumbsCategories.push({
      slug: `${pathTranslations.productsCategory}${category.slug}`,
      displayName: category.name || '',
    });
    breadcrumbsCategories.push({
      slug: `${pathTranslations.productsSubCategory}${category.slug}/${subcategory?.productSubcategoryPage?.slug}`,
      displayName: subcategory?.productSubcategoryPage?.subcategoryPageName || '',
    });
  }
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filteredProducts, setFilteredProducts] = useState<ProductGridProduct[]>(
    () => sortItems([...subCategoryProducts], currentSortMethod) as ProductGridProduct[],
  );
  const [currentFilters, setCurrentFilters] = useState<FilterTopic[]>(() =>
    getFiltersList(FilterItemType.PRODUCT, filteredProducts, searchItemLabels),
  );

  const filterProducts = (newFilters: FilterTopic[]) => {
    setCurrentFilters(newFilters);
    const products = filterItems(FilterItemType.PRODUCT, subCategoryProducts, newFilters);
    setFilteredProducts(sortItems(products, currentSortMethod) as ProductGridProduct[]);
  };

  useEffect(() => {
    setFilteredProducts(
      sortItems([...subCategoryProducts], currentSortMethod) as ProductGridProduct[],
    );
  }, [subCategoryProducts]);

  useEffect(() => {
    setFilteredProducts(
      (prevState) => sortItems([...prevState], currentSortMethod) as ProductGridProduct[],
    );
  }, [currentSortMethod]);

  useEffect(() => {
    TrackingService.contentGroups({
      cg1: 'product categories',
      cg2: category?.name || '',
    });
  }, []);

  const subcategoryData = {
    subHeading: subcategory?.subSubCategorySuper || '',
    heading: subcategory?.subSubCategoryTitle || '',
  };

  // returns card data
  const subcategoryCardsData = subcategory?.subSubcategoryPages?.map((subSubCategory: any) => ({
    productsAmount: `${ProductsService.getProductsAmount(subSubCategory) || 0}
      ${productsCopy?.value}`,
    id: subSubCategory?.id,
    image: {
      hero: subSubCategory?.keyImage?.asset?.[0]?.hero,
    },
    title: subSubCategory?.subcategoryPageName,
    link: {
      url: `${pathTranslations.productsSubCategory}${category.slug}/${subcategory?.slug}/${subSubCategory?.slug}`,
      displayName: '',
    },
  }));

  /**
   * Used to prepare the product data on the landing page for the click_see_product event
   */
  const clickSeeProductOnProductLandingPageContainer = (cardData: any) => {
    TrackingService.clickSeeProduct({
      name: cardData.title,
      category: cardData.category,
      subcategory: cardData.subCategory,
      tags: cardData?.metaKeywords || 'na',
      brand: cardData?.subbrand || 'Dr. Oetker',
      fillingQuantity: cardData?.fillingQuantity,
      filterCategory: TrackingService.getFilterCategoriesCommaSeparatedList(currentFilters) || 'na',
      filtersUsed: TrackingService.getFilterOptionsCommaSeparatedList(currentFilters) || 'na',
      sortingUsed: currentSortMethod,
    });
  };
  return (
    <>
      <FilterMenu
        id="subcategory-filter-menu"
        isOpen={showFilters}
        totalResults={filteredProducts.length}
        title={searchItemLabels.filters}
        onClose={() => setShowFilters(false)}
        onApplyFilters={(newFilters: FilterTopic[]) => filterProducts(newFilters)}
        filters={currentFilters}
        labels={searchItemLabels}
      />
      <Breadcrumbs
        titles={breadcrumbsCMSData}
        pageName={subcategory?.subcategoryPageName || ''}
        categories={breadcrumbsCategories}
      />
      {!heroCarouselData && <Spacer />}
      <div className="mx-auto flex flex-col items-center mb-30 relative md:mb-40">
        {heroCarouselData && (
          <>
            <SliderCarousel
              bannerMasterbrands={heroCarouselData?.bannerMasterbrands}
              banners={heroCarouselData?.banners}
              isForBanners
              type="carousel"
              primarySubBrandColor={subcategory?.primarySubBrandColor?.hex}
              watchVideoLabel={globalLabels.watch_video}
            />
            <Spacer height={30} />
          </>
        )}
        {introData && (
          <>
            <IntroText
              super={introData.super ?? ''}
              title={introData.title ?? ''}
              description={introData.description ?? ''}
              titleTag={introData.titleTag ?? ''}
            />
          </>
        )}
        {!!subcategory?.subSubcategoryPages?.length && (
          <Subcategory
            titles={subcategoryData}
            cardData={subcategoryCardsData as ICardItem[]}
            subcategoryCardVariation="product"
          />
        )}
      </div>
      <ProductGrid
        id="subcategory-product-grid"
        heading={subcategory?.productGridTitle || ''}
        products={filteredProducts}
        labels={searchItemLabels}
        currentSort={currentSortMethod}
        sortOptions={getAvailableSortMethods().map((m) => ({
          label: searchItemLabels[`sort_${m}`],
          value: m,
        }))}
        onSortProducts={(sm: string) => setCurrentSortMethod(sm)}
        onShowFilters={() => setShowFilters(!showFilters)}
        currentFiltersCount={currentFilters.length}
        trackingEvent={clickSeeProductOnProductLandingPageContainer}
      />
      {subcategory?.pageComponents && (
        <TemplateMapper
          components={subcategory?.pageComponents as AvailableTemplates[]}
          copy={[]}
          primarySubBrandColor={subcategory?.primarySubBrandColor?.hex}
          secondarySubBrandColor={subcategory?.secondarySubBrandColor?.hex}
          recipeCopy={recipeCopy}
          difficultyCopy={difficultyCopy}
          searchItemLabels={searchItemLabels}
          searchData={searchOverlayData}
        />
      )}
      {!!followUsData && (
        <SocialMedia
          id={followUsData.id}
          super={followUsData.super}
          title={followUsData.title}
          socialMedia={followUsData.socialMedia}
          middlegroundImage={followUsData.middlegroundImage}
          foregroundImage={followUsData.foregroundImage}
        />
      )}
    </>
  );
}

export default ClientProductsSubcategorySlugPage;
